const loc = window.location.pathname;
const galleries = document.querySelectorAll('.gallery');
const audioFiles = document.querySelectorAll('.audio');

function getBodyScrollTop() {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
}

if (document.getElementById('80s-workout')) {
    document.querySelector('main').classList.add('work-out');
}

if(loc.indexOf('most-creative') != -1) {
        let html = '<div id="mc-nav-wrap">';
            html += '<div id="mc-nav" class="container">';
            html += '<div class="row">';
            html += '<div class="col-12">';

            html += '<a href="/most-creative/sick-beats" '
                if(loc.indexOf('sick-beats') != -1) { html += 'class="active"'; }
                html += '>Sick beats</a>';

            html += '<a href="/most-creative/inevitable-news" '
                if(loc.indexOf('inevitable-news') != -1) { html += 'class="active"'; }
                html += '>Inevitable news</a>';

            html += '<a href="/most-creative/g-rated" '
                if(loc.indexOf('g-rated') != -1) { html += 'class="active"'; }
                html += '>G-rated</a>';

            html += '<a href="/most-creative/tired-greatness" '
                if(loc.indexOf('tired-greatness') != -1) { html += 'class="active"'; }
                html += '>Tired Greatness</a>';

            html += '<a href="/most-creative/lil-sugar" '
            if(loc.indexOf('lil-sugar') != -1) { html += 'class="active"'; }
            html += '>Lil Sugar</a>';


            html += '</div>';
            html += '</div>';
            html += '</div>';
            html += '<a href="#" id="mobile-toggle"><span></span></a>';
            html += '</div>';
        document.getElementById('page-top').innerHTML = html;
        document.body.classList.add("most-creative");
}


document.addEventListener("scroll", function(){
    setGallerySize();
    if(getBodyScrollTop() <= 20){
        document.body.classList.remove("scrolled");
    } else {
        document.body.classList.add("scrolled");
    }
});

const links = document.querySelectorAll(".enlarge");

for(let i=0; i<links.length; i++){
    links[i].addEventListener("click", function(e){
        const markup = '<div><img src="'+this.href+'" alt="" /></div>';
        document.body.classList.add("modal-open");
        document.getElementById('modal-content-wrap').style.height = (window.innerHeight - 100) + 'px';
        document.getElementById('modal-content').style.height = (window.innerHeight - 130) + 'px';
        document.getElementById('modal-content').innerHTML = markup;
        document.getElementById('modal-close').style.marginLeft = Math.floor((document.getElementById('modal-content-wrap').offsetWidth * 0.5)-30) + 'px';
        e.preventDefault();
    });
}

for(var i=0; i<galleries.length; i++){
    const gallery = galleries[i];
    gallery.style.height = (gallery.querySelector('.img-pos-1').offsetHeight + 10) + 'px';

    gallery.querySelector('.gallery-last-arrow').addEventListener('click',function(e){
        var cur = parseInt(this.parentNode.parentNode.getAttribute('data-current'));
        var ct = parseInt(this.parentNode.parentNode.getAttribute('data-count'));
        cur--;
        if(cur <= 0){ cur = ct; }
        this.parentNode.parentNode.className = 'gallery pos-' + cur;
        this.parentNode.parentNode.setAttribute('data-current', cur);

        e.preventDefault();
    });

    gallery.querySelector('.gallery-next-arrow').addEventListener('click',function(e){
        var cur = parseInt(this.parentNode.parentNode.getAttribute('data-current'));
        var ct = parseInt(this.parentNode.parentNode.getAttribute('data-count'));
        cur++;
        if(cur > ct){ cur = 1; }
        this.parentNode.parentNode.className = 'gallery pos-' + cur;
        this.parentNode.parentNode.setAttribute('data-current', cur);
        //alert(this.parentNode.parentNode.className);
        e.preventDefault();
    });

    const dots = gallery.querySelectorAll('.gallery-foot a');
    for(var j=0; j<dots.length; j++){
        dots[j].addEventListener('click',function(e){
            //alert(this.getAttribute('data-set'));
            this.parentNode.parentNode.className = 'gallery pos-'+this.getAttribute('data-set');
            this.parentNode.parentNode.setAttribute('data-current', this.getAttribute('data-set'));
            e.preventDefault();
        });
	}
}

function setGallerySize(){
    for(var i=0; i<galleries.length; i++){
        const gallery = galleries[i];
        gallery.style.height = (gallery.querySelector('.img-pos-1').offsetHeight + 10) + 'px';
    }
}

window.addEventListener('resize', function(){
    setGallerySize();
});

// document.querySelector("#modal-bg").addEventListener("click", function(e){
//     document.body.classList.remove("modal-open");
//     e.preventDefault();
// });
// document.querySelector("#modal-close").addEventListener("click", function(e){
//     document.body.classList.remove("modal-open");
//     e.preventDefault();
// });


if(document.getElementById('mobile-toggle')) {
    let mobileNav = false;

    window.addEventListener('resize', function(){
        navClose();
        //console.log(w);
    });

    document.querySelector("#mc-nav a").addEventListener("click", function(e){
        navClose();
    });

    document.getElementById('mobile-toggle').addEventListener("click", function(e){
        if(mobileNav == false) {
            const h = window.innerHeight;

            document.getElementById('mobile-toggle').classList.add("open");
            document.getElementById('mc-nav').classList.add("open");
            document.getElementById('mc-nav').style.height = h + 'px';
            mobileNav = true;
        } else {
            navClose();
        }
        e.preventDefault();
    });


    function navClose(){
        const w = window.innerWidth;
        mobileNav = false;
        document.getElementById('mobile-toggle').classList.remove("open");
        document.getElementById('mc-nav').classList.remove("open");
        document.getElementById('mc-nav').style.height = '40px';

        //mc-nav a
    }
}

function audioComponent() {
    Array.from(audioFiles).forEach((el) => {
        el.addEventListener('click', (event) => {
            let audioTrack = el.children[1];
            let playbtn = el.children[2];

            if (audioTrack.paused) {
                Array.from(audioFiles).forEach((el) => {
                    el.children[1].pause();
                    el.children[1].currentTime = 0;
                });
                
                audioTrack.play();
                playbtn.classList.add('hide');
            } 
            else {
                audioTrack.pause();
                audioTrack.currentTime = 0;
                playbtn.classList.remove('hide');
            }
        });
    });
}

(audioFiles.length > 1) ? audioComponent() : '';
